.show-vehicle-img {

	.img-holder2,
	.img-holder {
		width: 100%;
		object-fit: cover;
		cursor: pointer;
		border: 1px solid $border-color;
		border-radius: 5px;
		margin-bottom: 15px;
	}

	.img-holder {
		height: 150px;
	}

	.img-holder2 {
		height: 200px;
	}
}

.vehicle-img-details {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	.single {
		padding: 3px;
	}

	img {
		width: 100%;
		max-width: 220px;
		height: 180px;
		border: 1px solid $border-color;
		border-radius: 5px;
		object-fit: cover;
		cursor: pointer;
	}
}

@media (min-width: 1200px) {
	#photos_gallery {
		.modal-xl {
			width: 933px;
		}
	}

}

@media (min-width: 1400px) {
	#photos_gallery {
		.modal-xl {
			width: 996px;
		}
	}

}